import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import {SET_STEP_ONE} from "../../setup/redux/userReducer";

const CreateSale = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const history = useHistory()

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language

  const [name, setName] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [phone, setPhone] = useState('');
  const [familyPhone, setFamilyPhone] = useState('');
  const [familyRole, setFamilyRole] = useState('');
  const [familyRoles, setFamilyRoles] = useState([]);
  const [saleAlias, setSaleAlias] = useState('');
  const [salePartnerAlias, setSalePartnerAlias] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [gender, setGender] = useState('male');
  const [hasModal, setHasModal] = useState(false);
  const [hasModalWarning, setHasModalWarning] = useState(false);
  const [questionOne, setQuestionOne] = useState('');
  const [questionTwo, setQuestionTwo] = useState('');
  const [phoneContact, setPhoneContact] = useState('');
  const [address, setAddress] = useState('');
  const [website, setWebsite] = useState('');


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (name && phone && saleAlias) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [name, phone, saleAlias])

  const confirmRegisterSale = () => {
    setErrorMsg('')
    setIsCorrect(false)
    axios.post(`${BACKEND_URL}/customer/xmas-create-sale`, {
      name: name,
      address: address,
      phone_contact: phoneContact,
      website: website,
      sale_alias: saleAlias,
      phone: phone,
    }).then(res => res.data)
      .then(resData => {
        console.log({resData})
        if (resData.error_code === 1) {
          setErrorMsg('Đăng ký thành công, tự động tới trang đăng nhập sau 3 giây!')
          setTimeout(() => {
            window.location.replace('https://user.anbiz.vn/auth/login?phone=' + phone)
          }, 3000)
        } else {
          setIsCorrect(true)
          setErrorMsg(resData.message ?? 'Đăng ký không thành công, vui lòng thử lại hoặc liên hệ với quản trị viên!')
        }
      })
      .catch(err => {
        console.log({err})
        setIsCorrect(true)
        setErrorMsg('Lỗi hệ thống, vui lòng thử lại hoặc liên hệ với quản trị viên!')
      })
  }

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >

        {/*<div>*/}
        {/*  <img src={toAbsoluteUrl('/media/logos/company.jpg')} style={{ width: WIDTH }} alt='intro' />*/}
        {/*</div>*/}

        <div
          style={{
            width: '100%',
            padding: '30px'
          }}
        >
          <div>
            <p
              style={{
                fontSize: '18px',
                fontFamily: 'UTM-APTIMA',
                color: '#aeafce',
              }}
            >>> Tìm hiểu thông tin về công nghệ Anbi </p>
            <button
              onClick={() => {
                history.push('/intro2')
              }}
              type="button"
              style={{
                width: '100%',
                padding: '15px',
                backgroundColor: '#0dc735',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                borderWidth: '0px',
                fontFamily: 'UTM-APTIMA'
              }}
            >
            <span
              style={{
                fontSize: '22px',
                fontFamily: 'UTM-APTIMABOLD',
                color: '#ffffff',
                letterSpacing: 2.0
              }}
            >
              TÌM HIỂU ANBI
            </span>
            </button>
          </div>

          <div
            style={{
              paddingTop: '20px'
            }}
          >
            <p
              style={{
                fontSize: '18px',
                fontFamily: 'UTM-APTIMA',
                color: '#aeafce',
              }}
            >>> Đăng nhập nếu bạn đã có tài khoản </p>
            <a href="https://user.anbiz.vn/auth/login" target='_blank'>
              <button
                type="button"
                style={{
                  width: '100%',
                  padding: '15px',
                  backgroundColor: '#0dc735',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  borderRadius: '10px',
                  borderWidth: '0px',
                  fontFamily: 'UTM-APTIMA'
                }}
              >
              <span
                style={{
                  fontSize: '22px',
                  fontFamily: 'UTM-APTIMABOLD',
                  color: '#ffffff',
                  letterSpacing: 2.0
                }}
              >
                ĐĂNG NHẬP ANBI
              </span>
              </button>
            </a>
          </div>

        </div>

        <div
          style={{
            width: '100%',
            padding: '0px 30px 20px 30px'
          }}
        >
          <p style={{
            color: '#008ffe',
            fontSize: '24px',
            fontFamily: 'UTM-APTIMABOLD',
            marginTop: '20px',
            textAlign: 'center'
          }}>
            THÔNG TIN ĐĂNG KÝ
          </p>

          <h3
            style={{ color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}
          >
            1. Tên doanh nghiệp/ hộ kinh doanh:
          </h3>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />

          <h3
            style={{ marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}
          >
            2. Địa chỉ (nếu có):
          </h3>
          <input
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />

          <h3
            style={{ marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}
          >
            3. SĐT để khách hàng liên hệ (nếu có):
          </h3>
          <input
            value={phoneContact}
            onChange={(e) => setPhoneContact(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />

          <h3
            style={{ marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}
          >
            4. Website (nếu có):
          </h3>
          <input
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />

          <h3
            style={{ marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}
          >
            5. Mã đại lý (để khách hàng nhập vào khi nhận bài học đầy đủ, cũng như để xuất hiện thông tin quảng bá và cảm ơn doanh nghiệp trên tài khoản khách hàng, bao gồm chữ và số, không bao gồm ký tự đặc biệt, viết liền không dấu, từ 2 đến 20 ký tự):
          </h3>
          <input
            value={saleAlias}
            onChange={(e) => setSaleAlias(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}>
            6. SĐT đăng ký tài khoản quản lý (nếu SĐT này đã bị trùng thì bạn có thể thêm 1 hoặc 2 số vào phía sau):
          </h3>
          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />
          <p
            className='mt-3'
            style={{fontSize: '18px', color: '#3d99ff', fontFamily: 'UTM-APTIMA' }}
          >* Nếu bạn đăng ký tạo tài khoản quản trị thì sau khi tiến hành chuyển khoản tài trợ, bạn vui lòng truy cập website: user.anbiz.vn để đăng nhập tài khoản, trong lần đăng nhập đầu tiên thì SĐT và mật khẩu đều là SĐT bạn đã dùng để đăng ký tài khoản quản lý. Các thông tin bổ sung và các phiên bản cập nhật về sau sẽ được chúng tôi gửi trong tài khoản quản trị này.</p>


          <div
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {errorMsg && <h3
              style={{
                color: 'red'
              }}
            >{errorMsg}
            </h3>}

          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#0dc735' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={() => {
              confirmRegisterSale()
            }}
          >
            <span
              style={{
                fontSize: '22px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              ĐĂNG KÝ
            </span>
          </button>


        </div>

        <div
          style={{
            width: '100%',
            padding: '0px 30px 20px 30px'
          }}
        >
          <p style={{
            color: '#008ffe',
            fontSize: '24px',
            fontFamily: 'UTM-APTIMABOLD',
            marginTop: '20px',
            textAlign: 'center'
          }}>
            THÔNG TIN
            <br/>
            CHUYỂN KHOẢN TÀI TRỢ
          </p>


          <p
            className='mt-3'
            style={{fontSize: '18px', color: '#aeafce', fontFamily: 'UTM-APTIMA' }}
          >Nếu bạn đăng ký nhận đầy đủ toàn bộ các chức năng của phần mềm (phân tích và quản lý khách hàng, nhận bài học miễn phí cho khách, quảng bá doanh nghiệp, cập nhật các phiên bản theo thời điểm,…) thì mời bạn vui lòng chụp lại hoặc tải mã QR này về để chuyển khoản chính xác, kích hoạt hệ thống duyệt tự động của Anbi.</p>

          <div
            style={{ backgroundColor: '#333', height: '100%'}}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                margin: 'auto',
                // backgroundColor: 'red',
                backgroundSize: '100% 100%'
              }}
            >
              <div
                style={{
                  // borderRadius: '15px',
                  backgroundColor: 'white'
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'inline-block'
                  }}
                >

                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >

                                <span
                                  style={{
                                    color: '#333',
                                    fontSize: '20px',
                                    fontFamily: 'UTM-APTIMA'
                                  }}
                                >
                                  {'Ngân hàng: '}
                                </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '20px',
                        fontFamily: 'UTM-APTIMABOLD'
                      }}
                    >
                                  Quân đội MBBank
                                </span>
                  </div>

                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                                <span
                                  style={{
                                    color: '#333',
                                    fontSize: '20px',
                                    fontFamily: 'UTM-APTIMA'
                                  }}
                                >
                                  {'Số tài khoản: '}
                                </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '20px',
                        fontFamily: 'UTM-APTIMABOLD'
                      }}
                    >
                                  1988288288
                                </span>
                    <button className='btn btn-success btn-sm ms-4'
                            style={{ marginTop: '-10px' }}
                            onClick={() => navigator.clipboard.writeText('1988288288')}
                    >
                      Copy
                    </button>
                  </div>

                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'UTM-APTIMA'
                                }}
                              >
                                {'Chủ tài khoản: '}
                              </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '20px',
                        fontFamily: 'UTM-APTIMABOLD'
                      }}
                    >
                                Trần Ngọc Thiện
                              </span>
                  </div>


                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                                <span
                                  style={{
                                    color: '#333',
                                    fontSize: '20px',
                                    fontFamily: 'UTM-APTIMA'
                                  }}
                                >
                                  {'Số tiền chuyển khoản: '}
                                </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '20px',
                        fontFamily: 'UTM-APTIMABOLD'
                      }}
                    >
                                  2000000 đ
                                </span>
                  </div>

                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                                <span
                                  style={{
                                    color: '#333',
                                    fontSize: '20px',
                                    fontFamily: 'UTM-APTIMA'
                                  }}
                                >
                                  {'Nội dung: '}
                                </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '20px',
                        fontFamily: 'UTM-APTIMABOLD'
                      }}
                    >
                                    {'TAI TRO ANBI ' + saleAlias}
                                  </span>
                    <br/>
                    <button className='btn btn-success btn-sm ms-4'
                            style={{ marginTop: '0px' }}
                            onClick={() => navigator.clipboard.writeText('TAI TRO ANBI ' + saleAlias)}
                    >
                      Copy
                    </button>
                  </div>

                  <div
                    style={{
                      margin: '5px',
                      padding: '10px 10px 10px 0px',
                      textAlign: 'center'
                    }}
                  >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-APTIMA'
                                }}
                              >
                                {'Mã QR: '}
                              </span>
                    <br/>
                    <div className='text-center'>
                      <img style={{ width: '100%', borderRadius: '5px', marginTop: '10px' }} src={`https://img.vietqr.io/image/mbbank-1988288288-compact2.jpg?amount=2000000&addInfo=TAI%20TRO%20ANBI%20${saleAlias}&accountName=TRAN%20NGOC%20THIEN`} alt=""/>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

          <div>
            <p
              style={{
                fontSize: '18px',
                fontFamily: 'UTM-APTIMA',
                color: '#aeafce',
              }}
            >>> Hướng dẫn cài đặt và vận hành </p>
            <button
              onClick={() => {
                //history.push('/intro2')
              }}
              type="button"
              style={{
                width: '100%',
                padding: '15px',
                backgroundColor: '#0dc735',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                borderWidth: '0px',
                fontFamily: 'UTM-APTIMA'
              }}
            >
            <span
              style={{
                fontSize: '22px',
                fontFamily: 'UTM-APTIMABOLD',
                color: '#ffffff',
                letterSpacing: 2.0
              }}
            >
              CÀI ĐẶT VÀ VẬN HÀNH
            </span>
            </button>
          </div>

          <div
            style={{
              paddingTop: '20px'
            }}
          >
            <p
              style={{
                fontSize: '18px',
                fontFamily: 'UTM-APTIMA',
                color: '#aeafce',
              }}
            >>> Tìm hiểu thông tin về công nghệ Anbi </p>
            <button
              onClick={() => {
                //
                window.open('https://bom.so/AFdT7n', '_blank').focus();
              }}
              type="button"
              style={{
                width: '100%',
                padding: '15px',
                backgroundColor: '#0dc735',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                borderWidth: '0px',
                fontFamily: 'UTM-APTIMA'
              }}
            >
            <span
              style={{
                fontSize: '22px',
                fontFamily: 'UTM-APTIMABOLD',
                color: '#ffffff',
                letterSpacing: 2.0
              }}
            >
              TẢI PHẦM MỀM
            </span>
            </button>
          </div>

        </div>


      </div>

    </div>
  )
}

export {CreateSale}
